a {
  color: $primary;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: $primary;
  }
  &:focus {
    color: $primary;
    text-decoration: none;
  }
}
.state-map{
  min-height:500px;
  svg{
    width:100%;
    height:100%;
  }
}
.filter-blr-3{
  filter: blur(3px);
}
.overlay-0 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.text-small {
  font-size: 0.8rem;
}
.border-bottom-2 {
  border-bottom: 2px solid #dee2e6 !important;
}
.border-left-2 {
  border-left: 2px solid #dee2e6 !important;
}
.border-right-2 {
  border-right: 2px solid #dee2e6 !important;
}
.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.ml-25 {
  margin-left: 0.25rem !important;
}
.failed-track-msg {
  color: #6e6b7b;
}
.FreightAndRemittancePage {
  tr:nth-child(2) {
    th {
      border-bottom: 1px solid #ebe9f1 !important;
      background-color: rgba(27, 90, 143, 0.11);
    }
  }
}
table.scroll {
  tbody {
    max-height: 600px;
    overflow-y: auto;
  }
}
.freightPieLabels {
  .badge {
    width: 100%;
  }
}
// .refresh-tracking {
//   position: absolute;
//   right: 0;
//   top: 0;
//   cursor: pointer;
// }
.cursor-pointer {
  cursor: pointer;
}

.app-content {
  min-height: 100vh;
}

.content-wrapper {
  .card-header {
    background: transparent;
    border-bottom: 0;
  }
}
.alert {
  transition: 1s;
}
.channel-table {
  a {
    text-decoration: underline;
  }
  .badge-65-text {
    font-size: 0.65rem !important;
  }
  .badge-70-text {
    font-size: 0.7rem !important;
  }
}
.page-header {
  padding: 15px 1.5rem 15px;
  @at-root {
    .page-title {
      font-size: 1.2rem;
      color: #6e6b7b;
      text-transform: capitalize;
      font-weight: 500;
      line-height: 1.2;
      padding-bottom: 0rem !important;
      margin-bottom: 0.5rem;
      @include respond-to("small") {
        margin-bottom: 0;
      }
      @include border-left-short;
    }
  }
  .card-header-right {
    .btn-text {
      margin: 0.2rem !important;
      white-space: nowrap;
      height: 35px;
      display: flex;
      align-items: center;
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}

.sub-page-header {
  .sub-page-title {
    color: #6e6b7b;
    padding-bottom: 0.5rem !important;
    font-weight: 500;
    font-size: 1.2rem;
    @include border-left-short;
  }
}

.modal {
  .modal-dialog {
    height: auto;
  }
  .modal-content {
    .modal-header {
      .modal-title {
        font-size: 1.2rem;
        color: #6e6b7b;
        text-transform: capitalize;
        font-weight: 500;
      }
    }
    .modal-body {
      .form-group {
        label {
          color: #5e5873;
          font-size: 0.857rem;
          margin-bottom: 0.2857rem;
        }
      }
    }
  }
  .input-group-prepend .input-group-text {
    font-size: 14px;
  }
}

.form-group {
  label {
    color: #5e5873;
    font-size: 0.857rem;
    margin-bottom: 0.2857rem;
    font-weight: 400;
  }
  .error {
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
    width: 100%;
    font-weight: 500;
  }
}

.switch-md {
  &.custom-switch {
    padding-left: 0;
    .custom-control-label {
      padding-left: 3.5rem;
      line-height: 1.7rem;
      &:before {
        border: none;
        background-color: #e2e2e2;
        box-shadow: none !important;
        cursor: pointer;
        border-radius: 1rem;
        left: 0;
        top: 0;
        height: 1.7rem;
        width: 3rem;
        border-radius: 1rem;
      }
      &:after {
        left: 4px;
        top: 4px;
        width: 1rem;
        height: 1rem;
        box-shadow: -1px 2px 3px 0 rgba(34, 41, 47, 0.2);
        background-color: #fff;
        cursor: pointer;
      }
    }
    .custom-control-input {
      &:checked ~ .custom-control-label:before {
        border-color: $primary;
        background-color: $primary;
      }
      &:checked ~ .custom-control-label:after {
        transform: translateX(1.4rem);
        background-color: #fff;
      }
    }
  }
}
.swal-wide {
  width: 700px !important;
  max-width: 100%;
  padding: 0;

  .swal2-icon {
    width: 4em;
    height: 4em;
  }

  .swal2-html-container {
    font-size: 1.125em !important;
  }
  p {
    font-size: 0.8rem;
  }
  .swal2-actions {
    margin-top: 0;
    .swal2-confirm {
      margin-bottom: 0.5rem;
      border-color: #1b5a8f;
      background: #1b5a8f;
    }
  }
  .swal2-icon {
    margin-top: 0.5rem;
  }
  .swal2-title {
    padding-top: 0.5rem;
  }
}
// .PerformanceOverview {
//   .table {
//     text-align: center !important;
//   }
// }
.fixHeader-table {
  .table-responsive {
    overflow: auto;
    max-height: 500px;
  }
}
.dogoutsms {
  width: 100px;
  position: absolute;
  bottom: -1.25rem;
  right: 4rem;
}
.table-custom {
  thead {
    th {
      min-width: 150px;
    }
  }
}

.table-responsive::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.table-responsive::-webkit-scrollbar {
  width: $scrollbar;
  height: $scrollbar;
  background-color: #fff;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(70, 53, 250, 0.219);
  border-radius: 10px;
  &:hover {
    background-color: rgba($primary, 0.65);
  }
}

.list-group-item {
  color: #625f6e;
}

.custom-table {
  text-align: start !important;
  font-weight: 400;
  font-size: 0.9rem;
  color: #5e5873;

  a {
    text-decoration: underline;
  }

  .dropdown.show {
    position: static;
  }
  .dropdown-menu.show {
    max-height: 100%;
    overflow-y: auto;
  }

  .courier-logo {
    width: 60px;
    height: auto;
  }

  .avatar {
    margin-right: 5px;
    img {
      max-width: 60px;
      height: unset;
    }
  }

  .no-img {
    width: 60px;
    height: 60px;
  }

  thead {
    tr:first-child {
      th {
        background-color: $primarylightbg;
      }
    }
    tr:nth-child(2) {
      th {
        border-bottom: 1px solid #ebe9f1 !important;
      }
    }
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
    th {
      border-top: none;
      color: $primary !important;
      border: none;
      text-transform: uppercase;
      font-size: 14px;
      small {
        text-transform: initial;
        font-size: 12px;
      }
    }
  }
  tbody {
    border-top:unset !important;
    td {
      border: 0;
      border-bottom: 1px solid #ebe9f1;
      vertical-align: middle;
      .text-truncate {
        max-width: 300px;
      }
    }
  }
  .btn {
    padding: 0.1rem 0.5rem;
    font-weight: 500;
    font-size: $btn-xs-size;
  }
  .btn-outline-primary {
    @include btnSm($primary);
  }

  .text-primary {
    color: $primary !important;
    font-weight: 500;
  }
  .dropdown {
    .dropdown-item {
      font-size: 13px;
      padding: 0.3rem 1.28rem;
      color: #6e6b7b;
      font-weight: 600;
      text-decoration: none;
      &:hover {
        color: $primary;
        background: rgba(115, 103, 240, 0.12);
      }
    }
  }
  svg {
    width: 14px;
    height: 14px;
    font-size: 14px;
  }
  .dowbload-con {
    width: 80px;
    @at-root {
      .download-icon svg {
        font-size: 13px;
        cursor: pointer;
      }
    }
  }
}

.remittanceOverview {
  thead {
    th {
      small {
        position: absolute;
        left: 15px;
        bottom: -3px;
        z-index: 9999;
        font-size: 10px;
      }
    }
  }
}
.nodataImage {
  img {
    width: 150px !important;
  }
}
.signup-btn {
  font-size: 14px !important;
}
.btn-text {
  font-size: 13px;
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem;
  background: $primary;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  transition: all 0.15s ease-in-out;
  &:hover {
    text-decoration: none;
    background: $primary;
    color: #fff;
  }
  svg:not([class*="svg-inline--"]) {
    width: 18px;
    height: 18px;
  }
  &.btn-light:focus,
  &.btn-light:active {
    background: $primary !important;
    color: #fff !important ;
  }
}

.form-group {
  position: relative;
}

.form-control {
  @include inputBox;
}
select.form-control {
  padding-right: 1.5rem;
}

.cardFilter,
.dashboard {
  input:not(:placeholder-shown),
  .form-control.valid {
    background: #e8f0fe;
  }
}

.rmsc {
  .dropdown-container {
    border: 1px solid $input-border-color !important;
    @include inputBox;
    padding-right: 1.2rem;
    &:focus-within {
      border: 1px solid $primary !important;
      box-shadow: none !important;
    }
    & * {
      color: inherit !important;
    }
    .dropdown-heading {
      height: unset;
      padding: unset;
      svg.gray {
        position: absolute;
        // top: -3px;
        top: -4px;
        transform: scale(0.8);
        stroke-width: 2.4px;
      }
      .dropdown-search-clear-icon {
        right: -5px;
      }
      .dropdown-heading-dropdown-arrow {
        right: -20px;
      }
    }
    .dropdown-content {
      left: 0;
      z-index: 10;
      .panel-content {
        .search {
          input {
            height: 30px;
          }
        }
        .options {
          label {
            font-size: 12px;
          }
          .select-item {
            padding: 5px 10px;
            margin: 0;
          }
          .select-item.selected {
            color: $primary;
          }
          .item-renderer {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  &.active {
    .dropdown-container {
      background: #e8f0fe;
    }
  }
}

.showFilter {
  .dropdown-container {
    .dropdown-content {
      display: block;
      visibility: visible;
    }
  }
}

.rs-picker-toggle-wrapper {
  width: 100%;
  .rs-picker-toggle {
    @include inputBox;
    padding-top: 4px !important;
    line-height: 20px;
    & * {
      color: inherit !important;
    }
    .rs-picker-toggle-value {
      color: #6e6b7b;
    }
    .rs-picker-toggle-clean {
      font-weight: bold;
      top: 4px !important;
      svg path {
        stroke: #6e6b7b;
        stroke-width: 1.4px;
      }
    }
    .rs-picker-toggle-caret {
      top: 4px !important;
    }
    &:hover:not(:focus) {
      border-color: $input-border-color !important;
    }
    &.active {
      border-color: $primary !important;
    }
    &.rs-picker-toggle-active {
      border-color: #1b5a8f !important;
      box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
      &:hover {
        border-color: #1b5a8f;
      }
    }
  }
  &.rs-picker-has-value {
    .rs-picker-toggle {
      background: #e8f0fe !important;
    }
  }
}

.rs-picker-menu {
  z-index: 1093;
  &.fade.in {
    opacity: 1 !important;
  }
  .rs-picker-daterange-calendar-group {
    height: unset;
    min-width: unset;
    max-width: 290px;
    display: flex;
    flex-wrap: wrap;
    @include respond-to("small") {
      max-width: 100%;
    }
    @include respond-to-max("small") {
      .rs-calendar:first-child {
        border: unset;
      }
    }
  }
}

.form-group .arrowdown {
  position: absolute;
  right: 0.5rem;
  top: 0.4rem;
  color: #625f6e;
  font-size: 12px;
  pointer-events: none;
}

.input-group-text {
  font-size: 14px;
}

.input-group {
  .input-group-prepend {
    select.form-control {
      height: 25px;
      border: none;
      padding: 0px 0.8rem;
      padding-right: 1.5rem;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

.badge.badge-primary {
  @include badge($primary, rgba(115, 103, 240, 0.1));
}

.badge.badge-secondary {
  @include badge(#6a6b6d, rgba(130, 134, 139, 0.1));
}

.badge.badge-success {
  @include badge($success2, rgba(40, 199, 111, 0.1));
}

.timeline-icon-success {
  &:before {
    background: $success2 !important;
  }
}

.badge.badge-danger {
  @include badge($danger2, rgba(234, 84, 85, 0.1));
}

.timeline-icon-danger {
  &:before {
    background: #ea5455 !important;
  }
}

.badge.badge-warning {
  @include badge(#b36417, rgba(255, 159, 67, 0.1));
}

.timeline-icon-warning {
  &:before {
    background: #b36417 !important;
  }
}

.badge.badge-info {
  @include badge(#00cfe8, rgba(0, 207, 232, 0.1));
}

.timeline-icon-info {
  &:before {
    background: #00cfe8 !important;
  }
}

.badge.badge-dark {
  @include badge(#4b4b4b, rgba(75, 75, 75, 0.1));
}

.timeline-icon-dark {
  &:before {
    background: #4b4b4b !important;
  }
}

.badge.badge-light {
  // @include badge(#212529, #dededf);
  @include badge(#44494d, rgba(107, 107, 111, 0.1));
}

.timeline-icon-dark {
  &:before {
    background: #dededf !important;
  }
}

.badge-pill {
  // border-radius: 10rem;
  border-radius: 5px;
}

.btn {
  padding: 0.3rem 1rem;
  font-weight: 500;
  font-size: $btn-xs-size;
}
// .ctm-btn {
//   padding: 0.3rem 1rem;
//   font-weight: 500;
//   font-size: $btn-xs-size;
// }

.btn-primary {
  @include btn($primary);
}

.btn-outline-primary {
  @include btnSm($primary);
}

.text-primary {
  color: $primary !important;
}

.text-warning {
  color: $warning !important;
}
.text-warning-light {
  color: #f3974a !important;
}

.border-primary {
  border-color: $primary !important;
}

.bg-primary {
  background-color: $primary !important;
}

.btn-outline-orange {
  @include btnSm($warning);
}

.btn-orange {
  @include btn($warning);
}

.clr-333 {
  color: #333;
}

.table-footer {
  svg {
    width: 14px;
    height: 14px;
    font-size: 14px;
  }
  .pagination {
    .prev,
    .next {
      .page-link {
        width: 35px;
        height: 35px;
        overflow: hidden;
      }
    }
    .page-link {
      color: #625f6e;
      text-decoration: none;
      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }
  form {
    float: right;
    .form-group {
      max-width: 100px !important;
      position: relative;
    }
  }
}

.btn-container {
  width: auto;
}

.nav-tabs {
  .nav-item {
    font-weight: 600;
    .nav-link {
      cursor: pointer;
      &.active {
        color: $primary;
        border-top: 3px solid;
        border-color: $primary #dee2e6 #fff;
      }
      .tab-icon {
        margin-right: 0.5rem;
        @include respond-to-max("small") {
          margin-right: 0px;
        }
      }
      .tab-text {
        @include respond-to-max("small") {
          display: block;
        }
      }
    }
  }
}

.tab-content {
  padding-bottom: 1rem;
}

.right-side-anim {
  right: 0;
  top: 0;
  max-width: $track-odr-w;
  width: 100%;
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  border-left: 1px solid rgba(34, 41, 47, 0.05);
  background: #fff;

  &.hide {
    display: block !important;
    right: -$track-odr-w;
  }
  &.show {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    right: 0;
    // &:before {
    //   content: "";
    //   position: fixed;
    //   width: 100%;
    //   height: 100%;
    //   top: 0;
    //   left: 0;
    //   background: rgba(0, 0, 0, 0.25);
    // }
  }
  .custom-close {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    width: auto;
    z-index: 10;
    color: #5e5873;
    font-size: 1rem;
  }
  .main-card {
    height: 100vh;
    overflow: auto;
  }
  &.position-fixed {
    z-index: 1000;
    .main-card {
      height: 100vh;
      overflow: auto;
      &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(70, 53, 250, 0.219);
        border-radius: 10px;
      }
    }
    .card-header {
      position: fixed;
      max-width: $track-odr-w;
      width: 100%;
      z-index: 999;
      right: 0;
      transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
      &.hide {
        display: block !important;
        right: -$track-odr-w;
      }
      &.show {
        right: 0;
      }
    }
    .card-body {
      margin-top: 3rem;
    }
  }
  .progress-header {
    font-weight: 500;
  }
  .progress-area {
    background-color: rgba(15, 23, 33, 0.12);
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    .icon svg {
      width: 1.2rem;
      height: 1.2rem;
      animation: ring 4s 0.7s ease-in-out infinite;
      transform-origin: 50% 4px;
    }
    .progress-bar {
      border-radius: 10px;
    }
    .activity-progress {
      small {
        font-weight: 700;
      }
    }
  }
  .widget-timeline {
    padding-left: 2rem;
    font-weight: 500;
    .list-group-item {
      border: none;
      font-size: 12px;
      padding: 1.1rem 0px;
      background: none;
      .timeline-title {
        font-size: 12px;
        margin-bottom: 0.25rem;
        font-weight: bold;
      }
      .icon {
        font-size: 12px;
        margin-right: 8px;
      }
      &:before {
        content: "";
        position: absolute;
        left: -2rem;
        top: 1.1rem;
        border: 3px solid #fff;
        box-shadow: 1px 2px 6px 0 rgba(25, 42, 70, 0.3);
        border-radius: 50%;
        background: $primary;
        height: 13px;
        width: 13px;
        z-index: 2;
      }
      &:not(:last-child):after {
        content: "";
        position: absolute;
        left: calc(-2rem + 13px / 2);
        top: 17px;
        background: $primary;
        height: 100%;
        width: 1px;
        background: #dfe3e7;
        z-index: 1;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 998;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.fsc-2 {
  font-size: 0.8rem !important;
}

.fsc-3 {
  font-size: 0.9rem !important;
}

.fsc-4 {
  font-size: 0.75rem !important;
}

.p-75 {
  padding: 0.75rem !important;
}
.text-due{
  color:#d66234
}
.confirm-msg {
  font-size: 20px;
}

.bg-card-header {
  background: rgba(0, 0, 0, 0.03) !important;
}

.copy-btn {
  svg {
    width: 14px;
    height: 14px;
  }
  &:hover {
    color: $primary;
    cursor: pointer;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #5e5873;
}

.icon-sm svg {
  width: 1.2rem;
  height: 1.2rem;
}

.copied-con {
  width: 200px;
}

.showing-count {
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}

.total-select {
  border: none;
  background: rgba(241, 243, 244, 0.871);
  font-size: 13.5px;
  text-align: center;
  padding: 0.5rem;
}

.custom-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.p-tiny {
  padding: 0.1rem;
}

.form-check2 {
  display: inline-block;
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:before {
      content: "";
      -webkit-appearance: none;
      background-color: #fff;
      border: 1px solid #d8d6de;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      width: 1.2rem;
      height: 1.2rem;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 3px;
      margin-right: 0.5rem;
    }
  }
  input[type="radio"] + label {
    &:before {
      border-radius: 50%;
    }
  }
  input:checked + label:before {
    background-color: $primary;
    border-color: $primary;
  }
  input[disabled] + label:before {
    opacity: 0.65;
  }
  input:disabled:not(:checked) + label:before {
    opacity: 1;
    background-color: #efefef;
    border-color: #efefef;
  }
  input[type="checkbox"]:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 7px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.courier-list {
  .courier-box {
    .icon {
      width: 25px;
      height: 25px;
      border: 2px solid #d8d6de;
      font-size: 14px;
      border-radius: 50%;
      transition: all 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
      cursor: pointer;
      svg {
        opacity: 0;
      }
    }
    .card {
      border-radius: 10px;
      max-width: 450px;
      border: 2px solid transparent;
      transition: border 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;
    }
    input[type="radio"] {
      display: none;
    }
    input[type="radio"]:checked ~ label .icon-con .icon {
      border-color: $primary;
      color: $primary;
      svg {
        opacity: 1;
      }
    }
    input[type="radio"]:checked ~ label .card {
      border-color: $primary;
    }
  }
}

.channelIntegration {
  .integrate-box {
    .card {
      border: 2px solid transparent;
      transition: border 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
      font-size: 13px;
      font-weight: 500;
      .card-footer {
        background: transparent;
        text-align: center;
      }
    }
  }
}

.track-code {
  height: 200px;
  overflow: auto;
}

.cardFilter {
  .row {
    justify-content: flex-start !important;
  }
}
.w-product {
  max-width: 100%;
  width: 250px;
}
.w-some {
  min-width: 150px;
}

.showTop {
  position: relative;
  z-index: 1000;
  background: linear-gradient(#fff, #fff);
}

.dropdown {
  .dropdown-item {
    font-size: 13px;
    padding: 0.3rem 1.28rem;
    cursor: pointer;
    
    &:hover {
      color: $primary;
      background: $primarylightbg;
      svg {
        color: $primary;
      }
    }
  }
}

.download-img-con {
  position: relative;
  max-width: 120px;
  padding: 5px;
  border: 1px solid #dee2e6;
  &.pdf-con {
    .pdf-file {
      font-size: 0.857rem;
    }
    .container img {
      display: none;
    }
  }
  .container {
    max-width: 120px;
    width: 100%;
    margin: unset;
    padding: 0px;
  }
  .remove-img {
    position: absolute;
    left: 5px;
    top: 5px;
    color: #dc3545;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dc3545;
    border-radius: 50%;
    font-size: 14px;
    transition: all 100ms linear;
    &:hover {
      background: #dc3545;
      color: #fff;
    }
  }
}

.fixed-minw-100 {
  td {
    .form-control:not(select) {
      min-width: 85px;
      max-width: 85px;
    }
  }
}

.paymentmsg {
  .inner-paymentmsg {
    min-height: 100vh;
  }
  .paymentmsg-box {
    max-width: 400px;
  }
  .card-title {
    color: #333;
  }
  .paymentmsg-icon {
    width: 80px;
    height: 80px;
    border: 2px solid;
    border-radius: 50%;
    font-size: 40px;
  }
  .success-icon {
    color: $success2;
    border: 2px solid $success2;
  }
  .failed-icon {
    color: $danger2;
    border: 2px solid $danger2;
  }
}

.thankyou-msg {
  min-height: 100vh;
  .thankyou-msg-box {
    max-width: 500px;
  }
  .card-title {
    color: #333;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.w-40{
  width:40%
}
.w-20{
  width:20%
}
.selected-option-badge{
  position: absolute;
  right: 22px;
  top: 3px;
}
.choose-option{
  position: relative;
  background-color: transparent;
  &:focus{
    position: relative;
    background-color: transparent;
  }
}
.reload-card {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    cursor: pointer;
}
.shake {
  animation: shake 3.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.swal2-container {
  .swal2-popup {
    width: 450px;
    @include respond-to-max("small") {
      width: 300px;
      .swal2-icon {
        margin: 2em auto 0em;
        transform: scale(0.8);
      }
    }
    .swal2-title {
      font-size: 1.7rem;
    }
    .swal2-html-container {
      font-size: 20px;
    }
  }
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  // background-color: #9880ff;
  background-color: $warning;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  // background-color: #9880ff;
  background-color: $warning;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  // background-color: #9880ff;
  background-color: $warning;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    // background-color: #9880ff;
    background-color: $warning;
  }
  50%,
  100% {
    // background-color: #ebe6ff;
    background-color: rgba($warning, 0.2);
  }
}

.uploadFilecon {
  overflow: hidden;
  label {
    white-space: nowrap;
    overflow: hidden;
  }
}

.betternet-wrapper ~ iframe {
  display: none;
}
script ~ iframe {
  display: none;
}

.confetti-con{
  z-index: 9;
  position: fixed;
  top:0;
  left:50%;
  transform: translateX(-50%);
  width: 200px;
  height: 200px;
  user-select: none;
}
.border-right-none{
  border-right:none !important;
}
.invoice-table-min {
  min-height: 600px;
}
.h-400{
  height: 400px;
}

.confetti-animate-text a{
  animation: confettiAnmtText 20s linear;
  display: inline-block;
}

@keyframes confettiAnmtText {
  0%{
    transform: translate(10px, -5px) scale(1.4);
  }
  20%{
    color:#fdff6a;
  }
  40%{
    color:#29cdff;
  }
  60%{
    color:#78ff44;
  }
  80%{
    color:#ff718d;
  }
  100%{
    color:#a864fd;
  }
}