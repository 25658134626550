.header-navbar {
  position: fixed;
  width: calc(100% - 2rem);
  @include respond-to("large") {
    width: calc(100% - 4rem - #{$mainMenuW});
    margin: 0rem 2rem 0;
  }
  z-index: 12;
  right: 0;
  margin: 0rem 1rem 0;
  // border-radius: 0.428rem;
  padding: 0.9rem 1rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  top: 0rem;
  li a.nav-link {
    color: #6e6b7b;
    position: relative;
  }
  svg {
    color: #6e6b7b;
  }

  .navPrice {
    right: 0;
    margin-top: -10px;
    top: 1px;
    .badge {
      transform: translateY(-4px);
    }
  }
  .user-nav {
    line-height: 1.5;
    font-size: 14px;
    margin-right: 0.8rem;
    color: #6e6b7b;

    .user-name {
      // margin-right: 0.2rem;
      width: 120px;
      text-align: right;
    }
    .user-status {
      font-size: 11.6667px;
    }
  }
  .avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    // background-color: rgba(115, 103, 240, 0.12);
    background-color: $primarylightbg;
    color: $primary;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    .avatar-status-online {
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: rgba(115, 103, 240, 0.12);
      top: 1px;
      right: 15px;
    }
    img {
      box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12),
        0 2px 4px 0 rgba(34, 41, 47, 0.08);
    }
  }
  .dropdown {
    .dropdown-toggle {
      background: unset;
      &:focus {
        box-shadow: unset;
        background: unset;
      }
      &:active:focus {
        box-shadow: unset;
      }
    }
    .dropdown-menu {
      &.show {
        left: unset !important;
        transform: translate3d(-0px, 42px, 0px) !important;
      }
    }
    .title-card{
      font-size: 12px;
      padding: 0.3rem 1.28rem;
    }
    .linkact-con{
      color: $primary;
      background: $primarylightbg;
      a{
        color:inherit;
        font-size:12px;
        &:hover{
          background: unset;
        }
      }
    }
    .dropdown-item {
      font-size: 13px;
      padding: 0.3rem 1.28rem;

      &:hover {
        color: $primary;
        // background: rgba(115, 103, 240, 0.12);
        background: $primarylightbg;
        svg {
          color: $primary;
        }
      }

      svg {
        width: 16px;
        height: 16px;
        font-size: 16px;
      }
    }
  }
  .hoverableDropDownMenu{
    .dropdown-menu{
      right: -3rem;
      @include respond-to("medium") {
        right:0;
      }
      &.show{
        transform:translate3d(0px, 21px, 0px) !important;
      }
    }
    .dropdown-item{
      display: flex;
      flex-wrap: wrap;
      @include respond-to("medium") {
        flex-wrap:nowrap;
      }
      color:#5e5873;
      span:first-child{
        min-width:160px;
      }
      
    }
  }

  .search-input {
    float: left;
    .autocomplete-container {
      position: relative;
      display: none;
    }
    .search-input-icon {
      z-index: 2;
      display: none;
      // position: absolute;
      // top: 50%;
      // transform: translateY(-50%);
      // left: 1.5rem;
      // right: 1.5rem;
      cursor: pointer;
      // pointer-events: none;
      padding: 0;
      background: unset;
      border: unset;
      svg {
        height: 1.25rem;
        width: 1.25rem;
        font-size: 1.25rem;
      }
      @include respond-to-max("large") {
        margin: 0px 15px 0px 7px;
      }
    }
    .search-input-close {
      z-index: 1;
      display: none;
      position: absolute;
      top: 32%;
      // right: 1.5rem;
      right: 15px;
      cursor: pointer;

      @include respond-to-max("large") {
        position: initial;
      }
    }
    input {
      border: none;
      background: none;
      // padding: 1.6rem 3.6rem;
      padding: 10px 10px;
      line-height: 16px;
    }
    &.open {
      background: #fff;
      // border-radius: 0.5rem;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
      width: 100%;
      height: 100%;
      display: block;
      max-width: 350px;
      border-right: 1px solid #c9c9c9;
      .search-input-icon {
        display: block;
      }
      .search-input-close {
        @include respond-to("large") {
          display: none;
        }
        display: block;
      }

      form {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-left: 1rem;

        @include respond-to-max("large") {
          flex-direction: row-reverse;
          justify-content: flex-end;
        }
      }

      input {
        background: $primarylightbg;
        // background: rgb(255 255 255);
        // background: #f8f7fe;
        // border-right: 1px solid #c9c9c9;
        box-shadow: none;
        // width: 100%;
        // max-width: 100%;
        width: 100%;
        outline: none;
        height: auto;
        transition: all 0.3s ease-out;
        border-radius: unset;
        // background-clip: content-box !important;
      }
      .autocomplete-container {
        position: relative;
        display: block;
        margin-left: 0.5rem;
        // max-width: 260px;
        max-width: 285px;
        width: 100%;
        @include respond-to-max("large") {
          max-width: 300px;
        }
      }
    }
  }
}

.menu-collapsed {
  .header-navbar {
    width: calc(100% - 2rem);
    @include respond-to("large") {
      width: calc(100% - 4rem - #{$mainMenuCW});
    }
  }
}

.switch-act-con{
  .detail{
    font-size: 14px;
  }
  .swal2-popup .swal2-title{
    font-size: 1.5rem;
  }
  .swal2-html-container{
    margin: 1em 0.5em 0.3em;
    min-height: 65px;
  }
  .swal2-popup{
    width: 490px;
  }
  .right-aerro{
    position: relative;
    width:30px;
    height:18px;
    transform: scale(0.8);
    &::before{
      content: '';
      position: absolute;
      width:18px;
      height:18px;
      border:3px solid #5E5872;
      border-left:transparent;
      border-bottom:transparent;
      transform: rotate(45deg);
      top:0;
      right:0;
    }
    &::after{
      content: '';
      position: absolute;
      width:30px;
      height:2px;
      background: #5E5872;
      top:50%;
      transform: translateY(-50%);
      right:0;
    }
  }
}

.wallet_summary_modal{
  .modal-title{
    display:flex;
    justify-content: space-between;
    align-items:center;
    width: 100%;

    .logout{
      font-size: 14px;
    }
  }
}