.main-menu {
  background: #fff;

  .sideCollapse {
    // .fa-circle-notch {
    //   color: #13a6f2;
    //   width: 12px;
    // }
    // margin-right: 9px;
    @include respond-to("large") {
      top: 0;
      right: -25px;
      width: 25px;
      height: 25px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 17px;
        height: 17px;
      }
      .for-close {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    .for-close {
      display: block;
      &:hover {
        svg {
          color: $danger;
        }
      }
    }
    .for-open {
      display: none;
      &:hover {
        svg {
          color: $primary2;
        }
      }
    }
    right: 15px;
    position: absolute;
  }

  width: $mainMenuW;
  min-height: 100vh;
  box-shadow: 0px 0px 10px #2b2b2b17;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background 0s;
  // z-index: 999;
  z-index: 998;

  .scrollbar-container {
    overflow: auto;
    // &::-webkit-scrollbar {
    //   width: 6px;
    //   background: #fff;
    // }
    // &::-webkit-scrollbar-track {
    //   border-radius: 10px;
    // }
    // &::-webkit-scrollbar-thumb {
    //   border-radius: 10px;
    //   background: rgba(0, 0, 0, 0.3);
    // }

    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: $scrollbar;
      height: 6px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      // background-color: rgba(70, 53, 250, 0.219);
      background-color: $primarylightbg;
      border-radius: 10px;

      &:hover {
        background-color: rgba($primary, 0.65);
      }
    }
  }
  .navbar-header {
    // padding: 0.35rem 1rem 0.3rem 1.4rem;
    padding: 0.35rem 1rem 0.3rem 1rem;
    // height: 4.45rem;
    max-height: 4.45rem;
    min-height: 3.5rem;
    background: #fff;
    justify-content: start;
  }
  .main-menu-content {
    max-height: calc(100vh - 58px);
    position: relative;
  }
  .ul.navigation-main {
    overflow-x: hidden;
    padding-bottom: 0.75rem;
  }
  .navigation {
    background: #fff;
    // font-size: 0.963rem;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    padding-bottom: 1rem;
    margin: 0 15px;

    li {
      position: relative;
      width: 100%;

      a {
        // margin: 0 15px;
        padding: 10px 15px;
        color: #625f6e;
        line-height: 1.3;
        text-overflow: ellipsis;
        overflow: hidden;
        // transition: all 0.5s ease, background 0s, color 0s;
        transition: padding 0.5s ease, background 0s, color 0s;
        position: relative;
        &:hover {
          padding-left: calc(15px + 8px);
        }
        span:first-child {
          font-size: 20px;
          margin-right: 15.4px !important;
          svg {
            width: 20px;
            height: 20px;
          }
        }
        span:nth-child(2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: 0.4s ease;
          // width: unset;
          opacity: 1;
        }
        .du-icon {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          color: #625f6e;
          font-size: 10px;
          display: block;
        }
      }
      &.active {
        a {
          background: linear-gradient(118deg, $primary, rgba($primaryrgb, 0.7));
          // box-shadow: 0 0 10px 1px rgba($primaryrgb, 0.7);
          border-radius: 4px;
          color: #fff;
          font-weight: 400;
        }
      }
      &.sidebar-group-active > a {
        background: #f5f5f5;
        border-radius: 6px;
        color: #565360;
      }
      ul li a {
        padding: 10px 15px 10px 20px;
        &:hover {
          padding-left: calc(20px + 8px);
        }
      }
      // .collapse-css-transition {
      //   transition: all 3000ms linear;
      // }
      .collapse {
        // & {
        //   display: block !important;
        //   max-height: 0px;
        //   overflow: hidden;
        // }
        // &.show {
        //   max-height: 500px;
        // }

        ul {
          margin-left: 15px;
          transition: margin 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
          li a {
            span:first-child {
              svg {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
        // &.show {
        //   li {
        //     &:not(.active) a {
        //       background-color: $primarylightbg;
        //     }
        //   }
        // }

        li {
          &:not(.active) a {
            background-color: $primarylightbg;
          }
        }
        @include respond-to("large") {
          &:not(.show) {
            // display: block;
            // transition: all 300ms linear;

            // max-height: 0px;
            // overflow: hidden;
          }
        }
      }
      // @include respond-to("large") {
      //   &:hover > a + .collapse:not(.show) {
      //     display: block;
      //     max-height: 400px;
      //     transition: all 600ms linear;
      //   }
      // }

      // @include respond-to("large") {
      //   &:hover > a + .collapse:not(.show) {
      //     display: block;
      //     max-height: 400px;
      //     transition: all 600ms linear;
      //   }
      // }
    }
    .collapse-con {
      // border: 1px solid $input-border-color;
      // border-radius: 4px;
    }
  }
}

.menu-collapsed {
  .main-menu {
    margin-left: -$mainMenuW;

    @include respond-to("large") {
      width: $mainMenuCW;
      margin-left: 0;
      // overflow: hidden;
    }

    .sideCollapse {
      @include respond-to("large") {
        .for-close {
          display: none;
        }
        .for-open {
          display: block;
        }
      }
    }

    .navbar-header {
      justify-content: center;
      ul {
        @include respond-to("large") {
          overflow: hidden;
        }

        li {
          .navbar-brand {
            // padding-left: 5px;
            @include respond-to("large") {
              flex-direction: column;
              .brand-logo {
                display: none;
              }
              .brand-text {
                height: unset !important;
                width: 100% !important;
              }
            }
          }
          .brand-text {
            @include respond-to("large") {
              // opacity: 0;
              // display: none;
            }
          }
        }
      }
    }

    .navigation {
      .collapse {
        ul {
          margin-left: 0px;
        }
      }
    }

    &:hover {
      // width: $mainMenuW;
      // .sideCollapse {
      //   display: block;
      // }

      .navbar-header {
        ul {
          li {
            .brand-text {
              @include respond-to("large") {
                // opacity: 1;
                // display: block;
                // display: none;
              }
            }
          }
        }
      }

      .navigation {
        // margin: 0 15px;
        ul {
          // margin-left: 15px;
        }
        li {
          a {
            // flex-direction: row;
            // justify-content: start;
            span:nth-child(1) {
              @include respond-to("large") {
                // margin-right: 15.4px !important;
              }
            }
            span:nth-child(2) {
              // opacity: 1;
              @include respond-to("large") {
                // font-size: 14px;
                // white-space: nowrap;
                // text-align: left;
              }
            }
            .du-icon {
              display: block;
            }
          }
        }
      }
    }
    .navigation {
      margin: 0 10px;
      li {
        a {
          flex-direction: column;
          justify-content: center;
          span:nth-child(1) {
            @include respond-to("large") {
              margin-right: 0 !important;
            }
          }
          span:nth-child(2) {
            @include respond-to("large") {
              // opacity: 0;
              opacity: 1;
              font-size: 12px;
              white-space: unset;
              text-align: center;
            }
          }
          .du-icon {
            @include respond-to("large") {
              // display: none;
            }
          }
        }
      }
    }
  }
}
