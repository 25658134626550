$themeprimaryColor: #948bf3;
$midgray: #636363;
$scrollbarBG: #128612;
$thumbBG: #004e00;
$mainMenuW: 270px;
// $mainMenuCW: 80px;
$mainMenuCW: 120px;
$main-menu-w: 80px;
$primary: #ff5e14;
$primary2: #f27b45;
$primaryrgb: rgba(255, 80, 0,1);
$primary2rgb: rgb(4, 158, 241);
$primarylightbg: rgba(27, 90, 143, 0.11);
$primaryFocus: #5e50ee;
$edit: #0dcaf0;
$more: #f3f2f7;
$moreFocus: #e6e5e9;
$success: #198754;
$success2: #2f8153;
$warning: #fd7e14;
$danger: #dc3545;
$danger2: #cd4546;
$btn-sm-padding: 0.4rem 1rem;
$btn-xs-padding: 0.25rem 0.5rem;
$btn-sm-size: 0.875rem;
$btn-xs-size: 12px;
$input-size: 12px;
$input-h: 30px;
// $input-border-color: #a5a1a1;
$input-border-color: #ced4da;
$input-color: #6e6b7b;
$track-odr-w: 450px;
$scrollbar: 10px;

$breakpoints: (
  "small": 576px,
  "medium": 768px,
  "large": 992px,
  "extra-large": 1200px,
) !default;

$breakpoints2: (
  "small": 575.98px,
  "medium": 767.98px,
  "large": 991.98px,
  "extra-large": 1199.98px,
) !default;

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin respond-to-max($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints2, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints2, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints2)}.";
  }
}

@mixin btn($color) {
  border-color: $color;
  background: $color;
  &:hover {
    box-shadow: 0 8px 25px -8px $color;
    background: $color;
    border-color: $color;
  }
  &:focus {
    background-color: $color;
    box-shadow: 0 0 0 0.25rem rgba($color, 0.5);
    border-color: $color !important;
  }
  &:active {
    background-color: $color !important;
    border-color: $color !important;
  }
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba($color, 0.5) !important;
  }
}

@mixin btnSm($color) {
  border-color: $color;
  color: $color;
  &:hover {
    box-shadow: 0 8px 25px -8px $color;
    background: $color;
    border-color: $color;
    color: #fff;
  }
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba($color, 0.5);
    border-color: $color;
  }
  &:active {
    background: none !important;
    border-color: $color !important;
    color: $color !important;
  }
  &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba($color, 0.5) !important;
  }
}

@mixin inputBox {
  border-color: $input-border-color;
  color: $input-color !important;
  font-size: $input-size;
  padding: 0.4rem 0.8rem;
  height: $input-h;
  font-weight: 500;
  line-height: normal;
  // border-radius: 20px;
  &:focus {
    box-shadow: unset;
    border-color: $primary !important;
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  }
}

@mixin badge($color, $bg) {
  background-color: $bg;
  color: $color !important;
  padding: 0.3rem 0.5rem;
  font-size: 11.9px;
  text-transform: capitalize;
}

@mixin border-bottom-short($width: 100px) {
  position: relative;
  &::after {
    content: "";
    width: 100px;
    height: 4px;
    background: $primary;
    left: 0;
    bottom: 0;
    position: absolute;
    border-radius: 10px;
  }
}

@mixin border-left-short($width: 100px) {
  position: relative;
  &::after {
    content: "";
    width: 4px;
    height: 25px;
    background: $primary;
    left: -10px;
    top: 0;
    position: absolute;
    border-radius: 10px;
  }
}
